import type { EndpointDataType } from '@readme/api/src/mappings/page/reference/types';

import React, { useEffect } from 'react';

import { useAPIDesignerStore } from '.';

interface InitializeAPIDesignerStoreProps {
  apiObject?: EndpointDataType;
  children: React.ReactNode;
}

export function InitializeAPIDesignerStore({ children, apiObject }: InitializeAPIDesignerStoreProps) {
  const [isReady, initialize] = useAPIDesignerStore(s => [s.isReady, s.initialize]);

  if (!isReady && apiObject) {
    initialize({ apiObject });
  }

  useEffect(() => {
    if (apiObject) {
      initialize({ apiObject });
    }
  }, [initialize, apiObject]);

  return <>{children}</>;
}
