import type { ProjectClientSide } from '@readme/backend/models/project/types';

import React from 'react';
import { useLocation } from 'react-router-dom';

import { useSuperHubStore } from '@core/store';

import classes from './style.module.scss';

interface DeprecationWarningProps {
  currentVersion: string;
  lang: string;
  project: ProjectClientSide;
}

function DeprecationWarning({ currentVersion, lang, project }: DeprecationWarningProps) {
  const isEditing = useSuperHubStore(s => s.isEditing);
  const { pathname } = useLocation();
  const { siblings, stable, subdomain } = project;

  const origin = typeof document !== 'undefined' ? new URL(document.location.toString()).origin : '';

  // When switching to the active version, we return the user to the topmost feature path they're on.
  // E.g. if a user is at /v2/docs/test-page, switching to the active version will take them to /docs.
  const subdomainPath = siblings ? `/${subdomain}` : '';
  const langPath = lang !== 'en' ? `/lang-${lang}` : '';
  // @xxx: Using a malicious URL such as "readme.com/v1/%5cevil" will result in
  // `featurePath` being "/evil". Which will result in the anchor's href
  // resolving to https://evil. Uh oh! So let's just ignore any potentially
  // malicious looking strings.
  const featurePath = pathname.split('/')[1]?.replace(/^\\.*$/, '') || '';
  const stableVersionUrl = `${origin}${subdomainPath}${langPath}/${featurePath}`;

  if (isEditing) return null;

  return (
    <a className={classes.DeprecationWarning} href={stableVersionUrl}>
      These docs are for v{currentVersion}. Click to read the latest docs for v{stable.version}.
    </a>
  );
}

export default DeprecationWarning;
