import React, { useEffect, useRef } from 'react';
import { matchPath, useLocation, useHistory } from 'react-router-dom';

import type { SuperHubHashRouteParams, SuperHubRouteParams } from '@routes/SuperHub/types';
import { SuperHubHashRoutePaths, SuperHubRoutePaths } from '@routes/SuperHub/types';

import { useSuperHubStore } from '.';

export interface ConnectSuperHubStoreToRouterProps {
  children: React.ReactNode;
}

/**
 * List of hash `action` params that should *not* be preserved when location is
 * being updated.
 */
const excludedHashActions: SuperHubHashRouteParams['action'][] = ['configure'];

/**
 * Middleware component listens for Hub browser router updates and continually
 * updates the store state whenever the route changes.
 */
export function ConnectSuperHubStoreToRouter({ children }: ConnectSuperHubStoreToRouterProps) {
  const [updateRoute, isEditing] = useSuperHubStore(s => [s.updateRoute, s.isEditing]);
  const history = useHistory();
  const { pathname, hash } = useLocation();

  /** Maintains a record of the last location hash navigated to. */
  const lastHashRef = useRef<string>(hash);

  /** Whether we have forced the user into a temporary view mode after entering an appearance route. */
  const isInTemporaryViewModeRef = useRef<boolean>(false);

  // Responsible for temporarily switching to View mode when navigating
  // Appearance settings and then restoring them back to Edit mode when exiting.
  // TODO: Consider moving this logic out to a separate hook that only runs when
  // users are inside an Edit route.
  useEffect(() => {
    const match = matchPath<SuperHubRouteParams>(pathname, Object.values(SuperHubRoutePaths));
    const hashMatch = matchPath<SuperHubHashRouteParams>(hash.substring(1), Object.values(SuperHubHashRoutePaths));
    const {
      params: { section = null, slug = null },
    } = match ?? { params: {} };
    const {
      params: { action: hashAction },
    } = hashMatch ?? { params: {} };

    /**
     * If we are entering an appearance route and the user is currently in edit mode, we should temporarily
     * redirect them to the view mode of the current section. It isn't very useful to change the appearance
     * settings while in edit mode -- the user won't see the changes live updating,
     */
    const isEnteringAppearance = hash && hashAction === 'appearance';

    if (isEditing && isEnteringAppearance && !isInTemporaryViewModeRef.current) {
      history.replace({
        pathname: `/${[section, slug].filter(Boolean).join('/')}`,
        hash,
      });
      isInTemporaryViewModeRef.current = true;
      return;
    }

    /**
     * If the user exits a hash route with a temporary view mode state, attempt to return them to edit mode.
     */
    const isExitHash = hash !== '' && !hashAction;
    if (isExitHash && isInTemporaryViewModeRef.current) {
      /** Not all sections have all of the 'create', 'update', 'compare' edit mode route
       * actions and a user can navigate to different sections while in this temporary view mode. So we just
       * redirect them to the common 'update' route action to avoid a bad redirect. */
      const redirectUrl = `/${['update', section, slug].filter(Boolean).join('/')}`;

      /** Ensure the redirect is a valid edit mode route before redirecting. */
      const redirectMatch = matchPath<SuperHubRouteParams>(redirectUrl, SuperHubRoutePaths.editPage);
      if (redirectMatch) {
        history.replace(redirectUrl);
        isInTemporaryViewModeRef.current = false;
        lastHashRef.current = '';
        return;
      }

      isInTemporaryViewModeRef.current = false;
    }
  }, [hash, history, isEditing, pathname]);

  // Responsible for updating our store on every route change.
  useEffect(() => {
    /**
     * Preserve the HashRouter state when navigating to a new location without a hash.
     * This is useful when the user is navigating to a new page in the Hub while a hash
     * route driven UI is visible, like Appearance settings.
     */
    const toHashlessRoute = !hash;
    const previousHashMatch = toHashlessRoute
      ? matchPath<SuperHubHashRouteParams>(lastHashRef.current.substring(1), Object.values(SuperHubHashRoutePaths))
      : null;
    /** Whether to issue a redirect to preserve our last hash. */
    const preserveHash = !!previousHashMatch && !excludedHashActions.includes(previousHashMatch.params.action);

    if (preserveHash) {
      history.replace({
        pathname,
        hash: lastHashRef.current,
      });
    } else {
      updateRoute(pathname, hash);
    }

    // Keep record of last updated hash.
    lastHashRef.current = hash;
  }, [hash, history, pathname, updateRoute]);

  return <>{children}</>;
}
