import type { ReadAPIDefinitionType } from '@readme/api/src/mappings/apis/types';
import type { ReadCustomBlockGitCollectionType } from '@readme/api/src/mappings/customblock/types';
import type { ReadGuideType } from '@readme/api/src/mappings/page/guide/types';
import type { $TSFixMe } from '@readme/iso';

import { produce } from 'immer';
import lodashMerge from 'lodash/merge';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useReadmeApi, { useReadmeApiNext } from '@core/hooks/useReadmeApi';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import { useSuperHubStore } from '..';

import { getDefaultDocument } from './defaults';
import { isReferencePage } from './util';

interface ConnectSuperHubDocumentToApiProps {
  children: React.ReactNode;
  /**
   * Whether SWR should revalidate page data on focus. Default is `false`.
   */
  revalidateOnFocus?: boolean;
}

/**
 * Connects our SuperHub store's document slice to the page API endpoint via SWR
 * based on the current route section and slug. Fetches new data whenever
 * rendered and updates the store.
 */
export function ConnectSuperHubDocumentToApi({
  children,
  revalidateOnFocus = false,
}: ConnectSuperHubDocumentToApiProps) {
  const { section, slug } = useParams<SuperHubRouteParams>();
  const [getApiEndpoint, initialize, isEditing, isCreateNewPage, firstApiDefinition, defaultApiOptions] =
    useSuperHubStore(s => [
      s.getApiEndpoint,
      s.document.initialize,
      s.isEditing,
      s.editor.isCreateNewPage,
      s.apiDefinitions.data?.[0],
      s.defaultApiOptions,
    ]);

  const apiUrl = slug ? getApiEndpoint(slug) : null;

  // if we're in view mode and the section is reference, we need
  // the dereferenced/full API definition for the document
  const referenceOpts =
    !isEditing && section === 'reference'
      ? new URLSearchParams({ dereference: 'true', reduce: 'false' }).toString()
      : '';

  const documentUrl = apiUrl && referenceOpts ? `${apiUrl}?${referenceOpts}` : apiUrl;
  const {
    data: documentData = isCreateNewPage ? { data: getDefaultDocument(section) } : undefined,
    isLoading: isDocumentLoading,
    swrKey: swrKeyDocument,
  } = useReadmeApi<ReadGuideType>(documentUrl, {
    ...defaultApiOptions,
    swr: {
      revalidateOnFocus,
      shouldRetryOnError: true,
    },
  });

  // Temporarily limit custom block data fetching to only the docs and reference sections.
  // We'll want to load custom blocks for custom pages and changelogs once there's API support.
  const isCustomBlockRoute = ['docs', 'reference'].includes(section || '');

  const customBlocksUrl = apiUrl && isCustomBlockRoute ? `${apiUrl}/custom_blocks` : null;
  const {
    data: customBlocksData,
    isLoading: isCustomBlocksLoading,
    swrKey: swrKeyCustomBlocks,
  } = useReadmeApi<ReadCustomBlockGitCollectionType>(customBlocksUrl, {
    ...defaultApiOptions,
    swr: {
      revalidateOnFocus,
      shouldRetryOnError: true,
    },
  });

  const baseLegacyUrl = isEditing ? null : `/${section}/${slug}/customBlocks`;
  const legacyUrl = isCustomBlockRoute ? baseLegacyUrl : null;

  const { data: legacyData, isLoading: isLegacyDataLoading } = useReadmeApi<$TSFixMe>(legacyUrl, {
    swr: {
      revalidateOnFocus,
      shouldRetryOnError: true,
    },
    headers: {
      'x-requested-with': 'XMLHttpRequest',
    },
  });

  // When editing a reference endpoint page, get the URL to fetch the full API
  // definition from the existing document. If none exists (typically for new
  // endpoint pages), fallback to the first API definition of the project.
  const apiDefinitionUrl = useMemo(() => {
    if (section !== 'reference' || !isEditing) return null;
    if (!isReferencePage(documentData?.data)) return null;
    if (documentData.data.type !== 'endpoint') return null;

    return documentData.data.api.uri || firstApiDefinition?.uri || null;
  }, [documentData?.data, firstApiDefinition?.uri, isEditing, section]);

  const { data: apiDefinitionData, isLoading: isApiDefinitionLoading } = useReadmeApiNext<ReadAPIDefinitionType>(
    apiDefinitionUrl,
    {
      ...defaultApiOptions,
      swr: {
        revalidateOnFocus,
        shouldRetryOnError: true,
      },
    },
  );

  /**
   * Indicates whether data is loading or not. Because we require multiple API
   * fetches to hydrate document data, we must evalute multiple loading states.
   */
  const isLoading = isDocumentLoading || isCustomBlocksLoading || isApiDefinitionLoading || isLegacyDataLoading;

  /**
   * Indicates whether all initiated hydrations are synced up (e.g. both
   * document and custom blocks hydrations). This is important b/c we only want
   * to update our store after all request states are either pending or
   * completed. This is equivalent to a
   *
   * ```
   * Promise.all(documentFetch, customBlocksFetch, legacyDataFetch)
   * ```
   */
  const isLoadingStateStable = useMemo(() => {
    const loadingStates: boolean[] = [];

    // for every request that was potentially made, check whether the request
    // URL is non-null meaning we actually did initiate it. if it's non-null, we
    // want to include that request's loading state in our list of loading
    // states to compare.
    if (documentUrl) {
      loadingStates.push(isDocumentLoading);
    }
    if (customBlocksUrl) {
      loadingStates.push(isCustomBlocksLoading);
    }
    if (legacyUrl) {
      loadingStates.push(isLegacyDataLoading);
    }
    if (apiDefinitionUrl) {
      loadingStates.push(isApiDefinitionLoading);
    }

    // do all the loading states have the same value?
    return loadingStates.every(loadingState => loadingState === loadingStates[0]);
  }, [
    apiDefinitionUrl,
    customBlocksUrl,
    documentUrl,
    isApiDefinitionLoading,
    isCustomBlocksLoading,
    isDocumentLoading,
    isLegacyDataLoading,
    legacyUrl,
  ]);

  /** Page document that's been augmented with full API definition schema. */
  const nextDocumentData = useMemo(
    () =>
      produce(documentData, draft => {
        if (apiDefinitionData && isReferencePage(draft?.data)) {
          // Deep merge the current document's partial API definition schema
          // into the full API definition. Merges happen from left to right, and
          // the current document must always take precedence.
          draft.data.api.schema = lodashMerge({}, apiDefinitionData.data.schema, draft.data.api.schema);
          draft.data.api.uri = apiDefinitionData.data.uri;
        }
      }),
    [apiDefinitionData, documentData],
  );
  const customBlocks =
    documentData?.data && 'renderable' in documentData.data && !documentData?.data?.renderable?.status
      ? legacyData
      : customBlocksData?.data;

  useEffect(() => {
    if (isLoadingStateStable) {
      initialize({
        customBlocks: customBlocks || [],
        data: nextDocumentData?.data || null,
        isLoading,
        swrKeyCustomBlocks,
        swrKeyDocument,
      });
    }
  }, [
    initialize,
    isLoading,
    isLoadingStateStable,
    nextDocumentData?.data,
    swrKeyCustomBlocks,
    swrKeyDocument,
    documentData?.data,
    customBlocks,
  ]);

  return <>{children}</>;
}
