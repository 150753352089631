import type { $TSFixMe } from '@readme/iso';

import React, { useContext, useEffect } from 'react';

import { ProjectContext } from '@core/context';
import { useProjectStore, useSuperHubStore } from '@core/store';

import liveUpdateHub2CSS from './liveUpdateHub2CSS';

/**
 * Use this component for logic that needs to modify the document outside of the Hub's react root element
 * based on changes to the project store.
 *
 * Most updates should be handled by Hub components reactively updating from the project
 * store state, but there are some cases where we need to make updates to the Document DOM
 * outside of the Hub's root element.  For example updating the custom CSS or swapping a favicon.
 */
export function ConnectProjectStoreToDocument({ children }: { children?: React.ReactNode }) {
  // @todo: Using ProjectContext is a temporary solution until we can get the parent data in the apiV2 project representation
  const { project } = useContext(ProjectContext) as { project: $TSFixMe };
  const parentCSS = project?.parent?.appearance?.stylesheet_hub2 as string | undefined;

  const projectCSS = useProjectStore(s => s.data?.appearance?.custom_code?.css);
  const [isEditing, isSettingsLayout] = useSuperHubStore(s => [s.isEditing, s.layout === 'settings']);

  useEffect(() => {
    // See if we should disable custom CSS via queryparams.
    const searchParamsLowerCased = new URLSearchParams(window.location.search.toLowerCase());
    const disableCustomCss = searchParamsLowerCased.get('disablecustomcss') === 'true';

    // Only apply custom CSS styles when viewing pages. We clear custom CSS from the document when editing
    // or when viewing a settings layout where the Hub is not previewed.
    const styles =
      isEditing || isSettingsLayout || disableCustomCss ? '' : [parentCSS, projectCSS].filter(Boolean).join('');
    liveUpdateHub2CSS(styles);
  }, [isEditing, isSettingsLayout, parentCSS, projectCSS]);

  return <>{children}</>;
}
