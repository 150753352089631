import type { ReadVersionCollectionType } from '@readme/api/src/mappings/version/types';

import React, { useEffect } from 'react';

import useEnvInfo from '@core/hooks/useEnvInfo';
import useReadmeApi from '@core/hooks/useReadmeApi';

import { useSuperHubStore } from '..';

export interface InitializeSuperHubVersionsProps {
  children: React.ReactNode;

  /**
   * Initial versions data, typically sourced by SSR props generated by our
   * express controllers.
   */
  initialVersions?: ReadVersionCollectionType;

  /**
   * Whether to revalidate versions data on focus. Default is `false`.
   */
  revalidateOnFocus?: boolean;
}

/**
 * Initializes the SuperHubStore's version slice with initial data and connects
 * it to the git versions API endpoint to continually keep it in sync.
 */
export function InitializeSuperHubVersions({
  children,
  initialVersions,
  revalidateOnFocus = false,
}: InitializeSuperHubVersionsProps) {
  const { isServer } = useEnvInfo();
  const [apiBaseUrl, initialize, isReady] = useSuperHubStore(s => [
    s.apiBaseUrlWithoutVersion,
    s.versions.initialize,
    s.versions.isReady,
  ]);

  // PART 1: initialize our store during SSR and first CSR render if/when we
  // have initial versions data included in our SSR props generated by our
  // express controller. We must call this inline rather than inside an effect.
  if (isServer || !isReady) {
    initialize({
      data: initialVersions,
      error: null,
      isLoading: false,
    });
  }

  // PART 2: Connect to API via SWR to keep it in sync. This will continually
  // update our store with the API response in an async way. When revalidation
  // is disabled and initial data exists, we can skip the initial connection.
  const pageSize = initialVersions?.per_page || 100;
  const url = initialVersions && !revalidateOnFocus ? null : `/versions?per_page=${pageSize}`;
  const {
    data = initialVersions,
    error = null,
    isLoading,
    swrKey,
  } = useReadmeApi<ReadVersionCollectionType>(url, {
    apiBaseUrl,
    swr: {
      revalidateOnFocus,
      shouldRetryOnError: true,
    },
  });

  useEffect(() => {
    initialize({ data, error, isLoading, swrKey });
  }, [data, error, initialize, isLoading, swrKey]);

  return <>{children}</>;
}
